import { gql } from 'graphql-tag';

export const CREATE_STOCK_REQUEST = gql`
  mutation createStockRequest($input: CreateStockRequestInput!) {
    createStockRequest(input: $input) {
      id
    }
  }
`;

export const UPDATE_STOCK_REQUEST = gql`
  mutation updateStockRequest($id: ID!, $input: UpdateStockRequestInput!) {
    updateStockRequest(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_STOCK_REQUEST = gql`
  mutation deleteStockRequest($id: ID!) {
    deleteStockRequest(id: $id) {
      id
    }
  }
`;

export const GET_STOCK_REQUEST_CSV = gql`
  mutation GetStockRequestCsv($getStockRequestCsvId: ID!) {
    getStockRequestCsv(id: $getStockRequestCsvId)
  }
`;

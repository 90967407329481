import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Table } from 'antd';
import React, { useEffect } from 'react';
import { CREATE_TEAM, EDIT_TEAM, REMOVE_TEAM_MEMBER } from '../../lib/graphql/mutation/teams';
import { CreateTeam, CreateTeamVariables } from '../../lib/graphql/mutation/teams/__generated__/CreateTeam';
import { EditTeam, EditTeamVariables } from '../../lib/graphql/mutation/teams/__generated__/EditTeam';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { addEditTeamStyles } from './styles';
import { Team, TeamVariables } from '../../lib/graphql/query/teams/__generated__/Team';
import { TEAM } from '../../lib/graphql/query/teams';
import { RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables } from '../../lib/graphql/mutation/teams/__generated__/RemoveTeamMemberMutation';

interface Props {
  active: boolean;
  teamId?: string;
  teamName?: string;
  onClose: () => void;
}

const { Item } = Form;

export const AddEditTeam: React.FC<Props> = ({
  active,
  teamId,
  teamName,
  onClose,
}) => {
  const styles = addEditTeamStyles();

  const [createTeam, { loading: createLoading }] = useMutation<CreateTeam, CreateTeamVariables>(CREATE_TEAM, {
    onCompleted: (data) => {
      if (data.createTeam.id) {
        displaySuccessNotification('Team created successfully');
        onClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error creating team');
      }
    },
  });

  const [editTeam, { loading: editLoading }] = useMutation<EditTeam, EditTeamVariables>(EDIT_TEAM, {
    onCompleted: (data) => {
      if (data.editTeam.id) {
        displaySuccessNotification('Team edited successfully');
        onClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error editing team');
      }
    },
  });

  const [getTeamWithMembers, { data }] = useLazyQuery<Team, TeamVariables>(TEAM, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (teamId) {
      getTeamWithMembers({
        variables: {
          id: teamId,
          includeMembers: true,
        },
      });
    }
  }, [getTeamWithMembers, teamId]);

  const [removeTeamMember, { loading: removeMemberLoading }] = useMutation<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>(REMOVE_TEAM_MEMBER, {
    onCompleted: () => {
      if (teamId) {
        getTeamWithMembers({
          variables: {
            id: teamId,
            includeMembers: true,
          },
        });
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any) => {
    if (teamId) {
      editTeam({
        variables: {
          id: teamId,
          name: values.name,
        },
      });
    } else {
      createTeam({
        variables: {
          name: values.name,
        },
      });
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form, teamName, active]);

  return (
    <Modal
      title={teamId ? 'Edit Team' : 'Add Team'}
      open={active}
      onCancel={onClose}
      onOk={() => form.submit()}
      width={modalWidth}
      confirmLoading={createLoading || editLoading}

    >
      <div className={styles.container}>
        <Form
          layout="vertical"
          onFinish={onSubmit}
          form={form}
          initialValues={{
            name: teamName,
          }}
        >
          <Item
            label="Team Name"
            name="name"
          >
            <Input />
          </Item>
        </Form>
        <div>
          <h3>Team Members</h3>
          <Table
            columns={[
              {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
              },
              {
                title: 'Remove',
                key: 'remove',
                render: (item) => (
                  <Button
                    type="dashed"
                    onClick={() => {
                      if (teamId) {
                        removeTeamMember({
                          variables: {
                            teamId,
                            userId: item.id,
                          },
                        });
                      }
                    }}
                    loading={removeMemberLoading}
                  >
                    {removeMemberLoading ? 'Removing...' : 'Remove'}
                  </Button>
                ),
              },
            ]}
            dataSource={data?.team.members || []}
          />
        </div>
      </div>
    </Modal>
  );
};

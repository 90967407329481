import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Modal, Select, Table } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { OrderByDirection, StockRequestStatus } from '../../lib/graphql/globalTypes';
import { CREATE_STOCK_REQUEST, GET_STOCK_REQUEST_CSV, UPDATE_STOCK_REQUEST } from '../../lib/graphql/mutation/stockRequests';
import { createStockRequest as createStockRequestData, createStockRequestVariables } from '../../lib/graphql/mutation/stockRequests/__generated__/createStockRequest';
import { updateStockRequest as updateStockRequestData, updateStockRequestVariables } from '../../lib/graphql/mutation/stockRequests/__generated__/updateStockRequest';
import { PRODUCTS } from '../../lib/graphql/query/products';
import { Products, ProductsVariables } from '../../lib/graphql/query/products/__generated__/Products';
import { STOCK_REQUEST } from '../../lib/graphql/query/stockRequests';
import { stockRequest, stockRequestVariables } from '../../lib/graphql/query/stockRequests/__generated__/stockRequest';
import { USERS } from '../../lib/graphql/query/users';
import { Users, UsersVariables } from '../../lib/graphql/query/users/__generated__/Users';
import { capitalCase, displayErrorMessage, displaySuccessNotification, formatLargeNumbers } from '../../lib/utils';
import { modalWidth } from '../../styles/sizes';
import { addEditStockRequest } from './styles';
import { GetStockRequestCsv, GetStockRequestCsvVariables } from '../../lib/graphql/mutation/stockRequests/__generated__/GetStockRequestCsv';

interface Props {
  active: boolean | string;
  onClose: () => void;
}

const { Item } = Form;
const { Option } = Select;

export const AddEditStockRequest: React.FC<Props> = ({
  active,
  onClose,
}) => {
  const styles = addEditStockRequest();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [lineItems, setLineItems] = useState<any>([]);
  const [userLookup, setUserLookup] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [users, setUsers] = useState<any | undefined>([]);

  const handleOnClose = () => {
    form.resetFields();
    onClose();
  };

  const [getExistingStockRequest] = useLazyQuery<stockRequest, stockRequestVariables>(STOCK_REQUEST, {
    variables: {
      id: active as string,
    },
  });

  useQuery<Users, UsersVariables>(USERS, {
    variables: {
      page: 1,
      total: 1000,
      lookup: userLookup,
      orderBy: {
        direction: OrderByDirection.ASC,
        field: 'email',
      },
    },
    onCompleted: (data) => {
      setUsers(data?.users?.items);
    },
  });

  const [createStockRequest] = useMutation<createStockRequestData, createStockRequestVariables>(CREATE_STOCK_REQUEST, {
    onCompleted: (data) => {
      if (data.createStockRequest) {
        displaySuccessNotification('Stock Request created successfully');
        handleOnClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error creating Stock Request');
      }
    },
  });

  const [updateStockRequest] = useMutation<updateStockRequestData, updateStockRequestVariables>(UPDATE_STOCK_REQUEST, {
    onCompleted: (data) => {
      if (data.updateStockRequest) {
        displaySuccessNotification('Stock Request updated successfully');
        handleOnClose();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error updating Stock Request');
      }
    },
  });

  const [lookup, setLookup] = useState('');
  const { data } = useQuery<Products, ProductsVariables>(PRODUCTS, {
    variables: {
      page: 1,
      total: 20,
      lookup,
    },
  });

  const [getStockRequestCsv, { loading }] = useMutation<GetStockRequestCsv, GetStockRequestCsvVariables>(GET_STOCK_REQUEST_CSV, {
    onCompleted: (res) => {
      if (res.getStockRequestCsv) {
        const byteCharacters = atob(res.getStockRequestCsv);
        const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Stock Request - ${dayjs(data?.products.items[0].createdAt).format('YYYY-MM-DD')}.csv`);
        document.body.appendChild(link);
        link.click();
      }
    },
    onError: (err) => {
      if (err) {
        displayErrorMessage('Error downloading CSV');
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  const onFinish = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reducedLineItems = lineItems.map((item: any) => item.id !== 'total' && ({
      productId: item.categoryId ? item.id : item.name,
      quantity: item.quantity,
    }));
    if (typeof active === 'string') {
      updateStockRequest({
        variables: {
          id: active as string,
          input: {
            products: reducedLineItems,
            userId: form.getFieldValue('userId'),
            status: form.getFieldValue('status'),
          },
        },
      });
    } else {
      createStockRequest({
        variables: {
          input: {
            products: reducedLineItems,
            userId: form.getFieldValue('userId'),
          },
        },
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onAddProduct = (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values?.products?.forEach((item: any) => {
      const productItem = data?.products.items.find((sourceItem) => sourceItem.id === item);
      if (productItem) {
        setLineItems([...lineItems, { ...productItem, quantity: 1 }]);
      } else {
        setLineItems([...lineItems, {
          id: item,
          name: item,
          quantity: 1,
          price: 0,
        }]);
      }
    });
  };

  const [form] = useForm();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 400,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 30,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (quantity: string, record: any) => (record.id !== 'total' ? (
        <Input
          type="number"
          value={quantity}
          onChange={(e) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newLineItems = lineItems.map((item: any) => {
              if (item.id === record.id) {
                return { ...item, quantity: parseInt(e.target.value, 10) };
              }
              return item;
            });
            setLineItems(newLineItems);
          }}
        />
      ) : quantity),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (price: number, record: any) => (record.id !== 'total' ? `£${formatLargeNumbers(price || 0 * record.quantity)}` : `£${formatLargeNumbers(price || 0)}`),
      width: 50,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
  ];

  useEffect(() => {
    if (typeof active === 'string') {
      const stockRequestData = getExistingStockRequest();
      stockRequestData.then((returnData) => {
        if (returnData.data?.stockRequest) {
          setLineItems(returnData.data.stockRequest.lineItems);
          setUserLookup(returnData.data.stockRequest.user?.firstName as string);
          form.setFieldsValue({
            userId: returnData.data.stockRequest.user.id,
            status: returnData.data.stockRequest.status,
          });
        }
      });
    }
  }, [active, getExistingStockRequest, form]);

  return (
    <Modal
      title={`${typeof active === 'string' ? 'Edit' : 'Add'} Stock Request`}
      open={Boolean(active)}
      onCancel={handleOnClose}
      onOk={() => onFinish()}
      width={modalWidth + (modalWidth * 0.5)}
      okText={`${typeof active === 'string' ? 'Edit' : 'Add'} Stock Order`}
    >
      <div className={styles.container}>
        <Table
          columns={columns}
          dataSource={[...lineItems, {
            id: 'total',
            name: 'Total',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            quantity: lineItems.reduce((acc: number, item: any) => acc + item.quantity, 0),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            price: lineItems.reduce((acc: number, item: any) => acc + (item.price * item.quantity), 0),
          }]}
          pagination={false}
          key="id"
        />
        <Form
          layout="vertical"
          onFinish={onAddProduct}
          form={form}
        >
          <Item
            name="products"
            label="Products"
            required
          >
            <Select
              mode="tags"
              onSearch={(value: string) => setLookup(value)}
              filterOption={false}
              showSearch
            >
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {data?.products.items.map((item) => !lineItems.find((lineItem: any) => lineItem.id === item.id) && (
                <Select.Option
                  key={item.id}
                  value={item.id}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ float: 'right' }}
            >
              Add to stock order
            </Button>
          </Item>
          <Item
            name="userId"
            label="User"
          >
            <Select
              onSearch={(value: string) => setUserLookup(value)}
              filterOption={false}
              showSearch
            >
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                users.map((user: any) => (
                  <Option
                    key={user.id !== null ? user.id : '123'}
                    value={user.id !== null ? user.id : '123'}
                  >
                    {user.firstName !== null ? user.firstName : '123'}
                    {' '}
                    {user.lastName !== null ? user.lastName : '123'}
                  </Option>
                ))
              }
            </Select>
          </Item>
          {
            typeof active === 'string' && (
              <Item
                name="status"
                label="Status"
              >
                <Select>
                  {
                    // create an option for each StockRequestStatus
                    Object.keys(StockRequestStatus).map((status) => (
                      <Option
                        key={status}
                        value={status}
                      >
                        {capitalCase(status)}
                      </Option>
                    ))
                  }
                </Select>
              </Item>
            )
          }
        </Form>
        <Button
          type="default"
          onClick={() => getStockRequestCsv({
            variables: {
              getStockRequestCsvId: active as string,
            },
          })}
          loading={loading}
        >
          Download CSV
        </Button>
      </div>
    </Modal>
  );
};

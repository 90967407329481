import gql from 'graphql-tag';

export const TEAMS = gql`
  query Teams($total: Int!, $page: Int!, $orderBy: OrderBy) {
    teams(total: $total, page: $page, orderBy: $orderBy) {
      total
      items {
        id
        name
        members {
          id
          firstName
          lastName
        }
        status
        createdAt
        _count {
          members
        }
      }
    }
  }
`;


export const TEAM = gql`
  query Team($id: ID!, $includeMembers: Boolean) {
    team(id: $id, includeMembers: $includeMembers) {
      id
      name
      members {
        id
        email
      }
      status
      createdAt
    }
  }
`;
